import React from 'react'
import './styles.css'

const toMoney = value => {
  return (+value)
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
}

function JobInfo ({
  name,
  value,
  ...props
}) {
  const formatedValue = toMoney(value)
  return (
    <div className="job-info" {...props}>
      <div className="job-info__item">
        <div className="job-info__item__label">Projeto:</div>
        <div className="job-info__item__value">{name}</div>
      </div>
      <div className="job-info__item job-info__item--separator"></div>
      <div className="job-info__item">
        <div className="job-info__item__label">Valor:</div>
        <div className="job-info__item__value job-info__item__value--currency">{formatedValue}</div>
      </div>
    </div>
  )
}

export default JobInfo

import React from 'react'
import { connect } from 'react-redux'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { JobInfo } from '../JobInfo'
import { Spinner } from '../Spinner'
import { ScaleOut } from '../Animations'
import './styles.css'

function Main ({ job, children, ...props }) {
  return (
    <div className="layout layout--main" {...props}>
      <div className="layout__header">
        <div className="layout__header__wrapper">
          <ScaleOut>
            <Header />
          </ScaleOut>
          {job.loading && !job.data &&
            <div className="full-center p-20">
              <Spinner />
            </div>
          }
          {job.data &&
            <ScaleOut className="flex-grow">
              <JobInfo
                name={job.data.name}
                value={+job.data.value}
              />
            </ScaleOut>
          }
        </div>
      </div>
      <div className="layout__content-and-footer">
        <div className="layout__content">
          {children}
        </div>
        <div className="layout__footer">
          <Footer />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ job }) => ({ job })

export default connect(mapStateToProps, {})(Main)

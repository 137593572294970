import React, { forwardRef } from 'react'

const CardFormInputNumber = forwardRef(({
  center = false,
  onEnter,
  onBackEmpty,
  onKeyDown,
  ...props
}, ref) => {
  let className = ['card-form__control__input']
  if (center) {
    className.push('center')
  }
  className = className.join(' ')

  const onKeyDownCurrent = event => {
    if (event.keyCode === 13 && onEnter) {
      onEnter()
    }
    if (
      event.keyCode === 8 &&
      ref &&
      ref.current &&
      ref.current.value.length === 0 &&
      onBackEmpty
    ) {
      onBackEmpty()
    }
    if (typeof onKeyDown === 'function') {
      onKeyDown(event)
    }
  }

  return (
    <input
      type="text"
      className={className}
      inputMode="numeric"
      pattern="[0-9]*"
      ref={ref}
      onKeyDown={onKeyDownCurrent}
      {...props}
    />
  )
})

export default CardFormInputNumber

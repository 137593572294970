import React from 'react'
import './styles.css'

function Spinner ({ children, ...props }) {
  return (
    <div className="spinner" {...props}>{children}</div>
  )
}

export default Spinner

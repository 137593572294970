import React from 'react'
import './styles.css'

const Form = React.forwardRef(({
  children,
  ...props
}, ref) => {
  return (
    <form
      className="form"
      ref={ref}
      {...props}
    >
      {children}
    </form>
  )
})

export default Form

import React from 'react'
import { classnames } from '../../utils'
import './styles.css'

function Steps ({
  labels,
  children,
  ...props
}) {
  const labelsComps = labels.map((item, index) => {
    const classes = classnames({
      'steps__labels__label': true,
      'steps__labels__label--active': item.active
    })
    return (
      <div
        key={index}
        className={classes}
      >{item.label}</div>
    )
  })

  return (
    <div className="steps" {...props}>
      <div className="steps__labels">
        {labelsComps}
      </div>
      {children}
    </div>
  )
}

export default Steps

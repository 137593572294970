import React, { useState } from 'react'
import { Form, Input, RadioButton, FormInputError } from '../Form'
import { Button } from '../Button'
import { validator } from '../../utils'
import validations from './validations'

function ReceiptForm ({
  onSubmit,
  loading,
  children,
  ...props
}) {
  const [errors, setErrors] = useState([])
  const [sendType, setSendType] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const handleSubmit = async event => {
    event.preventDefault()
    setErrors([])
    const data = {
      sendType,
      email,
      phone
    }
    const result = await validator.validate(data, validations)
    if (result.success) {
      onSubmit(data)
    } else {
      setErrors(result.errors)
    }
  }

  const handleChange = fn => {
    return event => {
      const { value } = event.target
      fn(value)
    }
  }

  return (
    <div className="receipt-form" {...props}>
      <Form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
        <label
          className="form__control__label"
          style={{ marginBottom: '10px' }}
        >Enviar recibo por:</label>
        <div
          className="grid grid--gap-10 grid--2-columns"
          style={{ marginBottom: '20px' }}
        >
          <RadioButton
            name="send_type"
            label="E-mail"
            value="email"
            onChange={handleChange(setSendType)}
          />
          <RadioButton
            name="send_type"
            label="SMS"
            value="sms"
            onChange={handleChange(setSendType)}
          />
          <FormInputError name="sendType" errors={errors} />
        </div>
        {sendType === 'email' &&
          <React.Fragment>
            <Input
              label="E-mail"
              type="email"
              value={email}
              onChange={handleChange(setEmail)}
            />
            <FormInputError name="email" errors={errors} />
          </React.Fragment>
        }
        {sendType === 'sms' &&
          <React.Fragment>
            <Input
              label="Celular"
              type="phone"
              value={phone}
              onChange={handleChange(setPhone)}
              placeholder="(00) 00000-0000"
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            />
            <FormInputError name="phone" errors={errors} />
          </React.Fragment>
        }
        <div className="form__footer-button">
          <Button
            primary
            rounded
            loading={loading}
            type="button"
            onClick={handleSubmit}
          >Enviar</Button>
        </div>
      </Form>
    </div>
  )
}

export default ReceiptForm

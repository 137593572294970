import React from 'react'

function FormInputError ({
  name,
  errors,
  ...props
}) {
  const error = errors.find(item => item.field === name)
  if (error) {
    return (
      <div
        className="form__control__errors"
        {...props}
      >
        {error.message}
      </div>
    )
  }
  return false
}

export default FormInputError

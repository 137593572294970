import React, { useEffect, useRef } from 'react'
import { TweenMax, Expo } from 'gsap'

function ScaleOut ({
  delay = 0.9,
  initialScale = 0.85,
  children,
  ...props
}) {
  const divRef = useRef(null)

  useEffect(() => {
    TweenMax.fromTo(
      divRef.current,
      delay,
      { opacity: 0, scale: initialScale },
      { opacity: 1, scale: 1, ease: Expo.easeOut }
    )
  }, [divRef, delay, initialScale])

  return (
    <div
      ref={divRef}
      style={{ opacity: 0 }}
      {...props}
    >
      {children}
    </div>
  )
}

export default ScaleOut

import React from 'react'
import './styles.css'
import logo from '../../assets/nativos.svg'

function Logo ({ props }) {
  return (
    <div className="company-logo">
      <img src={logo} alt="Nativos" className="company-logo__image" />
      <div className="company-logo__name">
        <div className="company-logo__name__company">nativos</div>
        <div className="company-logo__name__separator">/</div>
        <div className="company-logo__name__slogan">pagamentos</div>
      </div>
    </div>
  )
}

export default Logo

export default {
  /**
   * Validate
   * @param {object} data
   * @param {object[]} validations
   * @returns {Promise<object>}
   */
  async validate (data, validations) {
    const output = {
      success: false,
      errors: []
    }

    for (const validation of validations) {
      try {
        const value = data[validation.field]
        await validation.validation(value, data)
      } catch (error) {
        output.errors.push({
          field: validation.field,
          message: error.message
        })
      }
    }

    if (output.errors.length === 0) {
      output.success = true
    }

    return output
  }
}

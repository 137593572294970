import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { CardForm } from '../CardForm'
import { Alert } from '../Alert'
import { Button } from '../Button'
import { ScaleOut } from '../Animations'
import { loadJob, payJob } from '../../store/actions/job'
import './styles.css'

function Payment ({
  job,
  loadJob,
  payJob,
  children,
  ...props
}) {
  const history = useHistory()
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [validPending, setValidPending] = useState(false)
  const { id } = useParams()
  const { data: jobData, loading } = job
  const amount = jobData
   ? +jobData.value
   : 0

  const onSubmit = rawData => {
    const data = {
      card_token: rawData.cardToken,
      payment_method_id: rawData.paymentMethodId,
      installments: +rawData.installments
    }
    payJob(id, data).then(({ success, data, message }) => {
      if (success) {
        history.push(`/confirmacao/${id}`)
      } else {
        setError(message)
      }
    }).catch(error => {
      setError(error.message)
    })
  }

  useEffect(() => {
    let title = 'Nativos Pagamentos'
    if (jobData) {
      title = `${jobData.name} / ${title}`
    }
    document.title = title
  }, [jobData])

  useEffect(() => {
    if (jobData && !validPending) {
      if (jobData.status === 'paid') {
        history.push(`/confirmacao/${id}`)
      } else if (jobData.status === 'cancelled') {
        setError('Essa cobrança foi cancelada')
      }
    }
  }, [jobData, history, id, validPending])

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      loadJob(id).then(response => {
        if (response) {
          if (!response.success) {
            setError(response.message)
          } else if (response.data.status === 'pending') {
            setValidPending(true)
          }
        } else {
          setError('Nosso servidor está fora do ar desculpe, tente novamente mais tarde')
        }
      })
    }
  }, [loaded, setLoaded, id, loadJob])

  return (
    <div className="page page--payment" {...props}>
      {!error && jobData && loaded &&
        <ScaleOut>
          <CardForm
            amount={amount}
            loading={loading}
            onSubmit={onSubmit}
          />
        </ScaleOut>
      }
      {error &&
        <div className="page__alert">
          <Alert
            icon="close"
            title="Ops, ocorreu um erro"
          >
            <p>{error}</p>
          </Alert>
          {jobData && jobData.status === 'pending' &&
            <div className="form__footer-button">
              <Button
                primary
                rounded
                type="button"
                onClick={() => setError(null)}
              >Tentar novamente</Button>
            </div>
          }
        </div>
      }
    </div>
  )
}

const mapStateToProps = ({ job }) => ({ job })
const mapDispatchToProps = {
  loadJob,
  payJob
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment)

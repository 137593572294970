import React from 'react'
import './styles.css'
import logo from '../../assets/mercadopago.svg'

function Logo ({ props }) {
  return (
    <div className="payment-method">
      <div className="payment-method__description">
        Ambiente Seguro
        <br />Tecnologia por:
      </div>
      <div className="payment-method__logo">
        <img src={logo} alt="Mercado Pago" className="payment-method__logo__image" />
      </div>
    </div>
  )
}

export default Logo

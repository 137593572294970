import React from 'react'
import check from '../../assets/icons/check.svg'
import close from '../../assets/icons/close.svg'
import './styles.css'

function Alert ({
  icon,
  title,
  children,
  ...props
}) {
  const images = { check, close }
  const image = images[icon]
  return (
    <div className="alert" {...props}>
      <div className="alert__icon">
        <img className="alert__icon__image" src={image} alt={title} />
      </div>
      <div className="alert__title">{title}</div>
      <div className="alert__content">{children}</div>
    </div>
  )
}

export default Alert

import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'

const Input = forwardRef(({
  label,
  mask,
  after,
  onEnter,
  ...props
}, ref) => {
  const onKeyDownCurrent = event => {
    if (event.keyCode === 13 && onEnter) {
      onEnter()
    }
  }

  return (
    <div className="form__control">
      <label className="form__control__label">{label}</label>
      {mask &&
        <MaskedInput
          ref={ref}
          className="form__control__input"
          mask={mask}
          onKeyDown={onKeyDownCurrent}
          {...props}
        />
      }
      {!mask &&
        <input
          ref={ref}
          className="form__control__input"
          type="text"
          onKeyDown={onKeyDownCurrent}
          {...props}
        />
      }
      {after}
    </div>
  )
})

export default Input

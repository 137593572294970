import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadJob, sendReceipt } from '../../store/actions/job'
import { Alert } from '../Alert'
import { ReceiptForm } from '../ReceiptForm'
import { Button } from '../Button'
import { ScaleOut } from '../Animations'
import { date } from '../../utils'
import './styles.css'

function Confirmation ({
  job,
  loadJob,
  sendReceipt,
  children,
  ...props
}) {
  const history = useHistory()
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const { id } = useParams()
  const { data: jobData, loading } = job
  const statusDate = jobData && jobData.status_date
    ? date.format(jobData.status_date)
    : null

  const onSubmit = rawData => {
    setError(null)
    const data = {
      send_type: rawData.sendType,
      email: rawData.email,
      phone: rawData.phone
    }
    sendReceipt(id, data).then(({ message }) => {
      setMessage(message)
    }).catch(({ message }) => {
      setError(message)
    })
  }

  useEffect(() => {
    document.title = 'Confirmação - Nativos Pagamentos'
  }, [])

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      loadJob(id).then(response => {
        if (response) {
          if (!response.success) {
            setError(response.message)
          } else if (response.data.status === 'pending') {
            setError('Essa cobrança ainda não foi paga')
          } else if (response.data.status === 'cancelled') {
            setError('Essa cobrança foi cancelada')
          }
        } else {
          setError('Nosso servidor está fora do ar desculpe, tente novamente mais tarde')
        }
      })
    }
  }, [loaded, setLoaded, id, loadJob, history])

  return (
    <div className="page page--confirmation" {...props}>
      {error &&
        <ScaleOut>
          <Alert
            icon="close"
            title="Ops, ocorreu um erro"
          >
            <p>{error}</p>
          </Alert>
          {jobData && jobData.status === 'pending' &&
            <div className="form__footer-button">
              <Button
                primary
                rounded
                type="button"
                onClick={() => history.push(`/pagamento/${id}`)}
              >Efetuar pagamento</Button>
            </div>
          }
        </ScaleOut>
      }
      {jobData && jobData.status === 'paid' &&
        <ScaleOut>
          <Alert
            icon="check"
            title="Pagamento confirmado!"
          >
            <div className="confirmation-data">
              <p className="confirmation-data__label">
                Código de confirmação
              </p>
              <p className="confirmation-data__code">
                {jobData.confirmation_code}
              </p>
              <p className="confirmation-data__date">
                Data do pagamento: {statusDate}
              </p>
            </div>
          </Alert>
          <ReceiptForm
            onSubmit={onSubmit}
            loading={loading}
          />
        </ScaleOut>
      }
      {message &&
        <p style={{
          textAlign: 'center',
          marginTop: '20px'
        }}>{message}</p>
      }
    </div>
  )
}

const mapStateToProps = ({ job }) => ({ job })
const mapDispatchToProps = {
  loadJob,
  sendReceipt
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation)

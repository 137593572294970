import React from 'react'
import { classnames } from '../../utils'

function StepItem ({
  active,
  children,
  ...props
}) {
  const classes = classnames({
    'steps__step-item': true,
    'steps__step-item--active': active
  })
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

export default StepItem

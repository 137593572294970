import React from 'react'
import { classnames } from '../../utils'
import './styles.css'

function Button ({
  rounded,
  primary,
  loading,
  link,
  small,
  children,
  ...props
}) {
  const classes = classnames({
    'button': true,
    'button--loading': loading,
    'button--rounded': rounded,
    'button--primary': primary,
    'button--link': link,
    'button--small': small
  })
  return (
    <button
      className={classes}
      {...props}
    >
      <div className="button--content">{children}</div>
    </button>
  )
}

export default Button

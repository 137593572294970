import React from 'react'
import './styles.css'

function Footer ({ ...props }) {
  return (
    <div className="footer" {...props}>
      Fique tranquilo(a), os dados do seu cartão <strong>não são armazenados em nosso servidor</strong>, somente o <strong>Mercado Pago</strong> tem acesso a eles através de uma comunicação segura (<strong>HTTPS</strong>).
    </div>
  )
}

export default Footer

import React, { useState, useRef } from 'react'
import MaskedInput from 'react-text-mask'
import CardFormInputNumber from './CardFormInputNumber'
import CardBrand from './CardBrand'
import CardFormInputError from './CardFormInputError'
import { Form, Input } from '../Form'
import { Button } from '../Button'
import { Steps, StepItem } from '../Steps'
import { card, validator, mp, masks } from '../../utils'
import validations from './validations'
import './styles.css'

function CardForm ({
  onSubmit,
  children,
  amount,
  loading,
  ...props
}) {
  const [errors, setErrors] = useState([])
  const [cardBrand, setCardBrand] = useState('')
  const [due, setDue] = useState('')
  const [step, setStep] = useState(0)
  const [cardNumber, setCardNumber] = useState('')
  const [name, setName] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [securityCode, setSecurityCode] = useState('')
  const [installments, setInstallments] = useState(1)
  const [installmentOptions, setInstallmentOptions] = useState([])
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [documentType, setDocumentType] = useState('CPF')
  const [documentTypeOptions, setDocumentTypeOptions] = useState([])
  const [documentPlaceholder, setDocumentPlaceholder] = useState('000.000.000-00')
  const [documentMask, setDocumentMask] = useState(masks.cpf)

  const formRef = useRef()
  const cardNumberHiddenRef = useRef()
  const cardExpirationMonthRef = useRef()
  const cardExpirationYearRef = useRef()
  const documentNumberRawRef = useRef()
  const numberGroup1Ref = useRef()
  const numberGroup2Ref = useRef()
  const numberGroup3Ref = useRef()
  const numberGroup4Ref = useRef()
  const nameRef = useRef()
  let dueRef
  const securityCodeRef = useRef()
  const documentNumberRef = useRef()

  const onChangeCardNumber = ref => {
    return event => {
      const { value } = event.target
      if (value.length === 4 && ref && ref.current) {
        ref.current.focus()
      }
      const cardNumberValue = [
        numberGroup1Ref.current.value,
        numberGroup2Ref.current.value,
        numberGroup3Ref.current.value,
        numberGroup4Ref.current.value
      ].join('')
      setCardNumber(cardNumberValue)
      cardNumberHiddenRef.current.value = cardNumberValue
      const info = card.info(cardNumberValue)
      if (info.card) {
        setCardBrand(info.card.type)
      }
      if (cardNumberValue.length === 16) {
        mp.getPaymentMethod({
          cardNumber: cardNumberValue,
          amount
        }).then(data => {
          setPaymentMethodId(data.paymentMethodId)
          setInstallmentOptions(data.installments)
        }).catch(error => {
          console.log('error', error)
        })

        mp.getIdentificationTypes().then(options => {
          setDocumentTypeOptions(
            options.map(item => ({
              value: item.id,
              text: item.name
            }))
          )
        }).catch(error => {
          console.log('error', error)
        })
      }
    }
  }

  const handleChange = (fn, options = {}) => {
    return event => {
      let { value } = event.target
      if (options.upper) {
        value = value.toUpperCase()
      }
      if (options.field === 'documentType') {
        setDocumentPlaceholder(
          value === 'CPF'
            ? '000.000.000-00'
            : '00.000.000/0000-00'
        )
        setDocumentMask(
          value === 'CPF'
            ? masks.cpf
            : masks.cnpj
        )
      }
      if (options.field === 'documentNumber') {
        documentNumberRawRef.current.value = value.replace(/[^\d]/g, '')
      }
      fn(value)
    }
  }

  const handleChangeDue = event => {
    const { value } = event.target
    const [monthValue, yearValue] = value.split('/')
    setMonth(+monthValue)
    setYear(2000 + (+yearValue))
    setDue(value)
    cardExpirationMonthRef.current.value = monthValue
    cardExpirationYearRef.current.value = yearValue
  }

  const onEnter = fn => event => {
    if (event.keyCode === 13 && onEnter) {
      fn()
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrors([])
    const validateData = {
      cardNumber,
      name,
      month,
      year,
      securityCode,
      installments,
      documentNumber
    }
    if (step === 0) {
      const result = await validator.validate(validateData, validations.stepOne)
      if (result.success) {
        setStep(1)
      } else {
        setErrors(result.errors)
      }
    } else {
      const result = await validator.validate(validateData, validations.stepTwo)
      if (result.success) {
        mp.createToken({ form: formRef.current })
          .then(response => {
            const data = {
              cardToken: response.id,
              installments,
              paymentMethodId,
              documentNumber,
              response
            }
            onSubmit(data)
          })
          .catch(error => {
            console.log(error)
            // @todo: show message in anywhere
          })
      } else {
        setErrors(result.errors)
      }
    }
  }

  const backStep = () => {
    setStep(0)
  }

  const preventInput = event => {
    event.preventDefault()
    event.stopPropagation()
    return false
  }

  const onKeyDownDue = event => {
    if (event.keyCode === 13 && onEnter) {
      securityCodeRef.current.focus()
    }
  }

  const cardNumberProps = {
    onSelect: preventInput,
    onDrag: preventInput,
    onDrop: preventInput,
    onPaste: preventInput,
    onCopy: preventInput,
    onCut: preventInput,
    onKeyDown: event => {
      const regex = /\d/
      if (!regex.test(event.key) && event.key.length === 1) {
        event.preventDefault()
      }
    },
    autoComplete: 'off'
  }

  const labels = [{
    label: '1. Dados do cartão',
    active: step === 0
  }, {
    label: '2. Dados pessoais',
    active: step === 1
  }]

  const buttonLabel = step === 0
    ? 'Próxima'
    : 'Pagar'

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <input
        type="hidden"
        ref={cardNumberHiddenRef}
        data-checkout="cardNumber"
      />
      <input
        type="hidden"
        ref={cardExpirationMonthRef}
        data-checkout="cardExpirationMonth"
      />
      <input
        type="hidden"
        ref={cardExpirationYearRef}
        data-checkout="cardExpirationYear"
      />
      <input
        type="hidden"
        ref={documentNumberRawRef}
        data-checkout="docNumber"
      />
      <Steps labels={labels}>
        <StepItem active={labels[0].active}>
          <div className="card-form" {...props}>
            <div className="card-form__card-brand">
              <CardBrand name={cardBrand} />
            </div>
            <div className="card-form__control">
              <div className="card-form__control__label">Número do cartão</div>
              <div className="card-form__group">
                <div className="card-form__group__item">
                  <CardFormInputNumber
                    ref={numberGroup1Ref}
                    onChange={onChangeCardNumber(numberGroup2Ref)}
                    placeholder="0000"
                    maxLength="4"
                    enterkeyhint="next"
                    center
                    {...cardNumberProps}
                  />
                </div>
                <div className="card-form__group__item">
                  <CardFormInputNumber
                    ref={numberGroup2Ref}
                    onChange={onChangeCardNumber(numberGroup3Ref)}
                    onBackEmpty={() => numberGroup1Ref.current.focus()}
                    placeholder="0000"
                    maxLength="4"
                    enterkeyhint="next"
                    center
                    {...cardNumberProps}
                  />
                </div>
                <div className="card-form__group__item">
                  <CardFormInputNumber
                    ref={numberGroup3Ref}
                    onChange={onChangeCardNumber(numberGroup4Ref)}
                    onBackEmpty={() => numberGroup2Ref.current.focus()}
                    placeholder="0000"
                    maxLength="4"
                    enterkeyhint="next"
                    center
                    {...cardNumberProps}
                  />
                </div>
                <div className="card-form__group__item">
                  <CardFormInputNumber
                    ref={numberGroup4Ref}
                    onChange={onChangeCardNumber(null)}
                    onEnter={() => nameRef.current.focus()}
                    onBackEmpty={() => numberGroup3Ref.current.focus()}
                    placeholder="0000"
                    maxLength="4"
                    enterkeyhint="next"
                    center
                    {...cardNumberProps}
                  />
                </div>
              </div>
              <CardFormInputError name="cardNumber" errors={errors} />
            </div>
            <div className="card-form__control">
              <div className="card-form__control__label">Nome no cartão</div>
              <input
                ref={nameRef}
                value={name}
                onChange={handleChange(setName, { upper: true })}
                onKeyDown={onEnter(() => dueRef.focus())}
                type="text"
                className="full card-form__control__input"
                enterkeyhint="next"
                autoCapitalize="characters"
                spellCheck="false"
                pattern="[A-Z]*"
                autoComplete="off"
                data-checkout="cardholderName"
              />
              <CardFormInputError name="name" errors={errors} />
            </div>
            <div className="card-form__group">
              <div className="card-form__group__item">
                <div className="card-form__control">
                  <div className="card-form__control__label">Vencimento (Mês/Ano)</div>
                  <MaskedInput
                    ref={ref => {
                      if (ref) {
                        dueRef = ref.inputElement
                      }
                    }}
                    inputMode="numeric"
                    value={due}
                    onChange={handleChangeDue}
                    onKeyDown={onKeyDownDue}
                    placeholder="00/00"
                    enterkeyhint="next"
                    className="card-form__control__input"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                    autoComplete="off"
                  />
                  <CardFormInputError name="month" errors={errors} />
                  <CardFormInputError name="year" errors={errors} />
                </div>
              </div>
              <div className="card-form__group__item">
                <div className="card-form__control">
                  <div className="card-form__control__label">Código de segurança</div>
                  <CardFormInputNumber
                    ref={securityCodeRef}
                    value={securityCode}
                    onChange={handleChange(setSecurityCode)}
                    onEnter={() => securityCodeRef.current.blur()}
                    placeholder="***"
                    maxLength="7"
                    enterkeyhint="done"
                    autoComplete="off"
                    data-checkout="securityCode"
                  />
                  <CardFormInputError name="securityCode" errors={errors} />
                </div>
              </div>
            </div>
            {installmentOptions.length > 0 &&
              <div className="card-form__control card-form__control--no-margin">
                <div className="card-form__control__label">Parcelas</div>
                <div className="card-form__control__select-box">
                  <select
                    className="full card-form__control__select"
                    onChange={handleChange(setInstallments)}
                  >
                    {installmentOptions.map(option => {
                      return (
                        <option
                          key={option.value}
                          value={option.value}
                        >{option.text}</option>
                      )
                    })}
                  </select>
                </div>
                <CardFormInputError name="installments" errors={errors} />
              </div>
            }
          </div>
        </StepItem>
        <StepItem active={labels[1].active}>
          <div className="grid grid--gap-10 mt-10">
            <div className="form__control form__control--no-margin">
              <div className="form__control__label">Tipo do documento</div>
              <div className="form__control__select-box">
                <select
                  className="full form__control__select"
                  onChange={handleChange(setDocumentType, { field: 'documentType' })}
                  data-checkout="docType"
                  value={documentType}
                >
                  {documentTypeOptions.map(option => {
                    return (
                      <option
                        key={option.value}
                        value={option.value}
                      >{option.text}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <Input
              ref={documentNumberRef}
              value={documentNumber}
              onChange={handleChange(setDocumentNumber, { field: 'documentNumber' })}
              label={documentType}
              placeholder={documentPlaceholder}
              mask={documentMask}
              inputMode="numeric"
              after={<CardFormInputError name="documentNumber" errors={errors} />}
              onEnter={() => documentNumberRef.current.inputElement.blur()}
              enterkeyhint="done"
            />
          </div>
        </StepItem>
      </Steps>
      <div className="form__footer-button">
        <Button
          rounded
          primary
          loading={loading}
          type="button"
          onClick={handleSubmit}
        >{buttonLabel}</Button>
      </div>
      {step === 1 &&
        <Button
          small
          link
          type="button"
          onClick={backStep}
        >Voltar</Button>
      }
    </Form>
  )
}

export default CardForm

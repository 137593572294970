import React from 'react'

import amex from '../../assets/icons/amex.svg'
import diners from '../../assets/icons/diners.svg'
import elo from '../../assets/icons/elo.svg'
import hiper from '../../assets/icons/hiper.svg'
import master from '../../assets/icons/master.svg'
import visa from '../../assets/icons/visa.svg'

function CardBrand ({ name, ...props }) {
  const icons = {
    amex: amex,
    'american-express': amex,
    'diners-club': diners,
    elo: elo,
    hiper: hiper,
    mastercard: master,
    maestro: master,
    visa: visa
  }
  const icon = icons[name]
  if (!icon) return false
  return (
    <img src={icon} alt={name} />
  )
}

export default CardBrand

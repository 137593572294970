import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Provider } from 'react-redux'
import { Main } from './components/Layouts'
import {
  Home,
  Payment,
  Confirmation
} from './components/Pages'

export default function App({ store }) {
  return (
    <Provider store={store}>
      <Router>
        <Main>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/pagamento/:id'>
              <Payment />
            </Route>
            <Route path='/confirmacao/:id'>
              <Confirmation />
            </Route>
            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </Main>
      </Router>
    </Provider>
  )
}

/**
 * Mercado Pago Utils
 */
export default {
  /**
   * Install Mercado Pago
   * @param {object} options
   * @param {string} options.key
   */
  install ({ key }) {
    window.Mercadopago.setPublishableKey(key)
  },

  /**
   * Get identification types
   * @returns {Promise<object>}
   */
  async getIdentificationTypes () {
    return new Promise((resolve, reject) => {
      try {
        window.Mercadopago.getIdentificationTypes((status, response) => {
          if (+status === 200) {
            resolve(response)
          } else {
            reject(new Error('Erro to get indentification types'))
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  },

  /**
   * Get method type
   * @param {object} options
   * @param {string} options.cardNumber
   * @param {number} options.amount
   * @returns {Promise<object>}
   */
  async getPaymentMethod ({ cardNumber, amount }) {
    return new Promise((resolve, reject) => {
      try {
        if (cardNumber.length >= 6) {
          const bin = cardNumber.substring(0, 6)
          window.Mercadopago.getPaymentMethod(
            { bin: bin },
            async (status, response) => {
              if (+status === 200) {
                const paymentMethodId = response[0].id
                const installments = await this.getInstallments({
                  paymentMethodId,
                  amount
                })
                resolve({
                  paymentMethodId,
                  installments
                })
              } else {
                reject(new Error(response.message))
              }
            }
          )
        } else {
          resolve(null)
        }
      } catch (error) {
        reject(error)
      }
    })
  },

  /**
   * Get installments
   * @param {object} options
   * @param {string} options.paymentMethodId
   * @param {number} options.amount
   * @returns {Promise<object>}
   */
  async getInstallments ({ paymentMethodId, amount }) {
    return new Promise((resolve, reject) => {
      try {
        window.Mercadopago.getInstallments({
          payment_method_id: paymentMethodId,
          amount
        }, (status, response) => {
          if (+status === 200) {
            resolve(
              response[0].payer_costs.map(installment => ({
                text: installment.recommended_message,
                value: installment.installments
              }))
            )
          } else {
            reject(new Error(response.message))
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  },

  /**
   * Create token
   * @param {object} options
   * @param {string} options.form
   * @returns {Promise<object>}
   */
  async createToken ({ form }) {
    return new Promise((resolve, reject) => {
      try {
        window.Mercadopago.createToken(form, (status, response) => {
          if (+status === 200) {
            resolve(response)
          } else {
            console.log('error response:', response)
            reject(new Error(response.message))
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  }
}

import React from 'react'
import { CompanyLogo } from '../CompanyLogo'
import { PaymentMethod } from '../PaymentMethod'
import './styles.css'

function Header ({ children, ...props }) {
  return (
    <div className="header" {...props}>
      <CompanyLogo />
      <PaymentMethod />
    </div>
  )
}

export default Header

const classnames = items => {
  const classes = []
  for (const name in items) {
    const value = items[name]
    if (value) {
      classes.push(name)
    }
  }
  return classes.join(' ')
}

export default classnames

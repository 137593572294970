import { api } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
} from './'

const identifier = 'job'

const _setJob = data => ({ type: 'SET_JOB', data })

export const loadJob = transactionCode => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get(`jobs/${transactionCode}`)
      .then(result => {
        if (result.success) {
          dispatch(_setJob(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const payJob = (transactionCode, params) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post(`jobs/${transactionCode}/pay`, params)
      .then(result => {
        if (!result.success) {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const sendReceipt = (transactionCode, params) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post(`jobs/${transactionCode}/receipt`, params)
      .then(result => {
        if (!result.success) {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

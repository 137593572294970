import React, { useEffect } from 'react'
import { ScaleOut } from '../Animations'

function Home ({ children, ...props }) {
  useEffect(() => {
    document.title = 'Ops - Nativos Pagamentos'
  }, [])

  return (
    <div className="page page--home" {...props}>
      <ScaleOut>
        <div className="home-message">
          <h1 className="home-message__title">Ops...</h1>
          <div className="home-message__message">Está faltando o código do seu pagamento, por favor verifique o link correto com quem enviou à você</div>
          <div className="home-message__footer">Equipe Nativos</div>
        </div>
      </ScaleOut>
    </div>
  )
}

export default Home

import isemail from 'isemail'

export default [{
  field: 'sendType',
  validation: value => {
    if (!value) {
      throw new Error('Escolha um método de envio')
    }
    return true
  }
}, {
  field: 'email',
  validation: (value, data) => {
    if (
      data.sendType === 'email' &&
      !isemail.validate(value)
    ) {
      throw new Error('E-mail inválido')
    }
    return true
  }
}, {
  field: 'phone',
  validation: (value, data) => {
    const regex = /\(\d{2}\) \d{5}-\d{4}/
    if (
      data.sendType === 'sms' &&
      !regex.test(value)
    ) {
      throw new Error('Digite seu número de celular')
    }
    return true
  }
}]

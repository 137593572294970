import React from 'react'

function RadioButton ({
  label,
  ...props
}) {
  return (
    <div className="form__control form__control--radio-button">
      <label className="form__control__label">
        <input
          className="form__control__radio"
          type="radio"
          {...props}
        />
        <span className="form__control__label__text">{label}</span>
      </label>
    </div>
  )
}

export default RadioButton

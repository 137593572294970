import cpf from 'cpf'
import { card } from '../../utils'

export default {
  stepOne: [{
    field: 'cardNumber',
    validation: value => {
      const info = card.info(value)
      if (!info.isValid) {
        throw new Error('Cartão inválido')
      }
      return true
    }
  }, {
    field: 'name',
    validation: value => {
      const parts = value.split(' ')
      if (!value || parts.length <= 1) {
        throw new Error('Digite o nome completo do cartão')
      }
      return true
    }
  }, {
    field: 'month',
    validation: value => {
      if (+value < 1 || +value > 12) {
        throw new Error('Mês inválido')
      }
      return true
    }
  }, {
    field: 'year',
    validation: value => {
      const currentYear = +((new Date()).getFullYear())
      if (+value < currentYear) {
        throw new Error('Ano inválido')
      }
      return true
    }
  }, {
    field: 'securityCode',
    validation: value => {
      if (value.toString().length < 3) {
        throw new Error('Código de segurança inválido')
      }
      return true
    }
  }],
  stepTwo: [{
    field: 'documentNumber',
    validation: value => {
      if (!cpf.isValid(value)) {
        throw new Error('CPF inválido')
      }
      return true
    }
  }]
}
